import { Dispatch } from 'redux'

import { BroodService } from '@Services'
import { IGeneralStore } from '@Interfaces'
import { ActionConsts } from '@Definitions'
import { getInitialLocale } from './helpers'
import { DictionaryActions } from '@Actions'

export const GeneralActions = {
  Set: (payload: IGeneralStore.Actions.ISetPayload) => {
    return {
      payload,
      type: ActionConsts.General.Set,
    }
  },
  GetInitialLocale: () => {
    return {
      type: ActionConsts.General.GetInitialLocale,
      payload: getInitialLocale(),
    }
  },
  SetLocale: (locale: string) => ({
    type: ActionConsts.General.SetLocale,
    payload: locale,
  }),

  FetchData: (professional: boolean = false) => {
    return async (dispatch: Dispatch) => {
      dispatch(GeneralActions.SetLoading(true))
      const data = await BroodService.GetGeneral(professional)
      dispatch(GeneralActions.SetLoading(false))

      if (data && data.data) {
        dispatch(GeneralActions.SetData(data.data))
        
        if (data.data.dictionary) {
          dispatch(DictionaryActions.SetDictionary(data.data.dictionary))
        }
      }
    }
  },
  SetData: (data: any) => ({
    type: ActionConsts.General.SetData,
    payload: data,
  }),

  SetLoading: (data: any) => ({
    type: ActionConsts.General.SetLoading,
    payload: data,
  }),

  SetCategorieSearch: (activeSearchCategories: any) => {
    return {
      type: ActionConsts.General.SetCat,
      payload: activeSearchCategories,
    }
  },

  SetGeneralSearchTerm: (searchTerm: any) => {
    return {
      type: ActionConsts.General.SetSearchTerm,
      payload: searchTerm,
    }
  }
}
