import { createMuiTheme } from '@material-ui/core/styles'

export const belgiumTheme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1030,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      light: '#FCF7EA',
      main: '#F0B709',
      dark: '#6F4401',
      contrastText: '#fff',
    },
    secondary: {
      light: '#F08807',
      main: '#458C04',
      dark: '#D85102',
      contrastText: '#fff',
    },
    error: {
      main: '#990c38',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#616161',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: "'Helvetica Neue', 'Arial', sans-serif",
    fontSize: 14,
    h1: {
      fontFamily: "'Melbourne', sans-serif",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '28px',
    },
    h2: {
      fontFamily: "'Melbourne', sans-serif",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '28px',
    },
    h3: {
      fontFamily: "'Melbourne', sans-serif",
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.7)',
      },
    },
  },
})
