import { ActionConsts, TranslationKeys } from '@Definitions'
import { IAction, IDictionaryStore } from '@Interfaces'

type IActionPayload = IDictionaryStore.Actions.IDictionaryPayload | string

/**
 * INITIAL_STATE
 */
export const INITIAL_STATE: IDictionaryStore.IInitialState = {
  dictionary: TranslationKeys,
}

/**
 * REDUCER
 */
export const DictionaryReducer = (
  state: IDictionaryStore.IInitialState = INITIAL_STATE,
  action: IAction<IActionPayload>,
) => {
  switch (action.type) {
    case ActionConsts.Dictionary.SetDictionary:
      return {
        ...state,
        dictionary: action.payload,
      }

    case ActionConsts.Dictionary.ResetReducer:
      return {
        ...state,
        dictionary: INITIAL_STATE,
      }

    default:
      return state
  }
}

export default DictionaryReducer
