import { Http } from '@Services'
import getConfig from 'next/config'
import { BroodModel } from '@Interfaces'

const logError = (name: string, e: ExceptionInformation) => {
  console.log(`__START__ERROR__${name}`)
  console.log(e)
  console.log(`__END__ERROR__${name}`)
}

const {
  publicRuntimeConfig: { IS_PROFESSIONAL },
} = getConfig()

export const BroodService = {
  GetGeneral: async (professional: boolean = false): Promise<BroodModel.GetGeneralResponse> => {
    let response: BroodModel.GetGeneralResponse

    try {
      const link =
        professional || IS_PROFESSIONAL === 'true' ? '/general/professionals' : '/general'
      response = await Http.Request<BroodModel.GetGeneralResponse>('GET', `${link}`)
    } catch (error) {
      response = {} as BroodModel.GetGeneralResponse
    }

    return response
  },
  GetPage: async (slug: string, querystring: string) => {
    let response: BroodModel.GetPageResponse
    try {
      response = await Http.Request<BroodModel.GetPageResponse>(
        'GET',
        `/page?slug=${slug !== '/' ? slug : ''}${querystring ? `&${querystring}` : ''}`,
      )
    } catch (error) {
      if (error.status === 301 && 'json' in error) {
        const json = await error.json()
        response = {
          success: false,
          redirect: json.data.url,
        } as BroodModel.GetPageResponse
      } else {
        response = {} as BroodModel.GetPageResponse
      }
    }
    return response
  },
  PostContactForm: async (data: BroodModel.RequestPostContactFormPayload) => {
    try {
      return await Http.Request<BroodModel.RequestPostContactFormPayload>(
        'POST',
        `/general/askquestionfaq`,
        undefined,
        data,
      )
    } catch (e) {
      logError('RequestPostContactForm', e)
      return {
        error: e,
      } as BroodModel.RequestPostContactFormResponse
    }
  },
  PostContactFormProf: async (data: BroodModel.RequestPostContactFormPayload) => {
    try {
      return await Http.Request<BroodModel.RequestPostContactFormPayload>(
        'POST',
        `/general/contact/professionals`,
        undefined,
        data,
      )
    } catch (e) {
      logError('RequestPostContactFormProf', e)
      return {
        error: e,
      } as BroodModel.RequestPostContactFormResponse
    }
  },
  GetGeneralSearch: async (searchTerm: string): Promise<BroodModel.GetGeneralSearchResponse> => {
    try {
      return await Http.Request<BroodModel.GetGeneralSearchResponse>('GET', '/general/searchfaq', {
        searchTerm,
      })
    } catch (e) {
      logError('GetGeneralSearch', e)
      return { error: e } as BroodModel.GetGeneralSearchResponse
    }
  },
  GetRecipes: async (
    searchTerm: string,
    filters: string,
    pageNumber: number,
  ): Promise<BroodModel.GetRecipeSearchResponse> => {
    try {
      return await Http.Request<BroodModel.GetRecipeSearchResponse>(
        'GET',
        '/general/searchrecipe',
        {
          searchTerm,
          filters,
          pageNumber,
        },
      )
    } catch (e) {
      logError('GetRecipeSearch', e)
      return { error: e } as BroodModel.GetRecipeSearchResponse
    }
  },
  GetNews: async (pageNumber: number): Promise<BroodModel.GetNewsResponse> => {
    try {
      return await Http.Request<BroodModel.GetNewsResponse>('GET', '/general/pagingnews', {
        pageNumber,
      })
    } catch (e) {
      logError('GetNews', e)
      return { error: e } as BroodModel.GetNewsResponse
    }
  },
  GetBlogs: async (pageNumber: number): Promise<BroodModel.GetBlogsResponse> => {
    try {
      return await Http.Request<BroodModel.GetBlogsResponse>('GET', '/general/pagingblog', {
        pageNumber,
      })
    } catch (e) {
      logError('GetBlogs', e)
      return { error: e } as BroodModel.GetBlogsResponse
    }
  },
  PostNewsletterForm: async (data: BroodModel.RequestPostNewsletterFormPayload) => {
    try {
      // Implementeren van Spotler
      console.log(data)
      // return await Http.Request<BroodModel.RequestPostNewsletterFormPayload>(
      //   'POST',
      //   `/general/askquestionfaq`,
      //   undefined,
      //   data,
      // )
    } catch (e) {
      logError('RequestPostContactForm', e)
      return {
        error: e,
      } as BroodModel.RequestPostNewsletterForm
    }
  },
  GetSearchPage: async (
    searchTerm: string,
    categories: string,
    pageNumber: number,
  ): Promise<BroodModel.GetSearchPageResponse> => {
    try {
      return await Http.Request<BroodModel.GetSearchPageResponse>('GET', '/general/searchpage', {
        searchTerm,
        categories,
        pageNumber,
      })
    } catch (e) {
      logError('GetSearchPage', e)
      return { error: e } as BroodModel.GetSearchPageResponse
    }
  },
}
