import { ActionConsts } from '@Definitions'
import { IAction, IBlogsStore } from '@Interfaces'

type IActionPayload = IBlogsStore.Actions.IDataPayload | string

/**
 * INITIAL_STATE
 */
export const INITIAL_STATE: IBlogsStore.IInitialState = {
  pageNumber: 1,
  blogs: {
    blogPosts: [],
    currentPage: 1,
    totalPages: 0,
  },
}

/**
 * REDUCER
 */
export const BlogsReducer = (
  state: IBlogsStore.IInitialState = INITIAL_STATE,
  action: IAction<IActionPayload>,
) => {
  switch (action.type) {
    case ActionConsts.Blogs.SetData:
      return {
        ...state,
        blogs: action.payload,
      }

    default:
      return state
  }
}

export default BlogsReducer
