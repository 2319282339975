export function getHrefOfSlug(slug: string) {
  if (slug === '/') {
    return slug
  }

  // remove last character for stripping last '/', doesn't matter if it is a different character
  const str = slug.slice(0, slug.length - 1)
  // count amount of 'slashes'
  const amountOfSlashes = (str.match(/\//g) || []).length

  return `/[slug]${amountOfSlashes > 1 ? '/[subSlug]' : ''}${
    amountOfSlashes > 2 ? '/[subsubSlug]' : ''
  }${amountOfSlashes > 3 ? '/[subsubsubSlug]' : ''}`
}

export const GTMPageView = (url: string) => {
  interface PageEventProps {
    event: string
    page: string
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  }

  // @ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent)
  return pageEvent
}

export const GTMDownloadEvent = (link: string) => {
  interface DownloadEventProps {
    event: string
    link: string
  }

  const downloadEvent: DownloadEventProps = {
    event: 'pageview',
    link,
  }

  // @ts-ignore
  window && window.dataLayer && window.dataLayer.push(downloadEvent)
  return downloadEvent
}
