import { ActionConsts } from '@Definitions'
import { IAction, IRecipesStore } from '@Interfaces'

type IActionPayload = IRecipesStore.Actions.IDataPayload | string

/**
 * INITIAL_STATE
 */
export const INITIAL_STATE: IRecipesStore.IInitialState = {
  filters: [],
  searchTerm: '',
  pageNumber: 1,
  recipes: {
    recipes: [],
    totalRecipes: 0,
    currentPage: 1,
    totalPages: 0,
  },
}

/**
 * REDUCER
 */
export const RecipesReducer = (
  state: IRecipesStore.IInitialState = INITIAL_STATE,
  action: IAction<IActionPayload>,
) => {
  switch (action.type) {
    case ActionConsts.Recipes.SetFilters:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionConsts.Recipes.SetSearchTerm:
      return {
        ...state,
        searchTerm: action.payload,
      }

    case ActionConsts.Recipes.ResetReducer:
      return {
        ...state,
        filters: [],
        pageNumber: 1,
        recipes: {
          recipes: [],
          totalRecipes: 0,
          currentPage: 1,
          totalPages: 0,
        },
      }

    case ActionConsts.Recipes.SetData:
      return {
        ...state,
        recipes: action.payload,
      }

    default:
      return state
  }
}

export default RecipesReducer
