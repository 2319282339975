import 'isomorphic-unfetch'
import getConfig from 'next/config'
import { stringify } from 'query-string'

import { HttpModel } from '@Interfaces'

const {
  publicRuntimeConfig: { API_URL, API_LANG },
} = getConfig()

export const Http = {
  accessToken: '',
  language: API_LANG,

  SetAccessToken: (token: string) => {
    Http.accessToken = token
  },

  SetLanguage: (lang: string) => {
    Http.language = lang
  },

  Request: async <A>(
    methodType: string,
    endpoint: string,
    queryParams?: HttpModel.IRequestQueryParams,
    payload?: HttpModel.IRequestPayload,
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      // remove empty params
      let filteredQueryParams = queryParams
      if (filteredQueryParams) {
        for (const key of Object.keys(filteredQueryParams)) {
          if (filteredQueryParams[key] === '') {
            delete filteredQueryParams[key]
          }
        }
      }
      const query = queryParams ? `?${stringify({ ...filteredQueryParams })}` : ''
      fetch(`${API_URL}${endpoint}${query}`, {
        body: JSON.stringify(payload),
        cache: 'no-cache',
        headers: {
          'content-type': 'application/json',
          lang: Http.language ? Http.language : 'nl', // TODO: deze moet obv taalswitch gekozen worden
        },
        method: `${methodType}`,
      })
        .then(async (response: any) => {
          if (response.status === 200 || response.status === 404) {
            return response.json().then(resolve)
          } else {
            return reject(response)
          }
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },
}
