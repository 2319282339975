import cookie from 'cookie'
export const languages = [
  { label: 'EN', locale: 'en-US', lang: 'en' },
  { label: 'NL', locale: 'nl-NL', lang: 'nl' },
]

export const parseCookies = (req?: any) => {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie)
}

/**
 * DI-597 Temporarily turn off language picker and always
 * return en-US as initial locale.
 * */
export const getInitialLocale = (req?: any): string => {
  return req ? languages[0].locale : languages[0].locale
}

/**
 * DI-597 Temporarily turn off language picker and always
 * return en-US as initial locale.
 * */
// export const getInitialLocale = (req?: any): string => {
//   const locales = languages.map(language => language.locale)
//   try {
//     const cookies = parseCookies(req)
//     let initialLanguage = cookies.locale
//     if (initialLanguage && locales.some(locale => locale === initialLanguage)) {
//       return initialLanguage
//     } else {
//       return languages[0].locale
//     }
//   } catch (error) {
//     return languages[0].locale
//   }
// }

export const getInitialLanguageObject = (req: any) => {
  const locale = getInitialLocale(req)
  return languages.find(language => language.locale === locale) || languages[0]
}

export const getInitialLanguage = (req: any) => {
  return getInitialLanguageObject(req).lang
}
