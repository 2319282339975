import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
import { TimelineMax, TweenMax } from 'gsap'
import ScrollToPlugin from 'gsap/src/uncompressed/plugins/ScrollToPlugin'
const plugins = [ScrollToPlugin]

let ScrollMagicModule: any = null
if (typeof window !== 'undefined') {
  ScrollMagicModule = require('scrollmagic')
  ScrollMagicPluginGsap(ScrollMagicModule, TweenMax, TimelineMax)
  if (!ScrollMagicModule) {
    console.log(plugins)
  }
}

export const ScrollMagic = ScrollMagicModule
