import { ActionConsts } from '@Definitions'
import { BroodService } from '@Services'
import { Dispatch } from 'redux'

export const RecipeActions = {
  Reset: () => ({
    type: ActionConsts.Recipes.ResetReducer,
  }),

  SetSearchTerm: (term: string) => ({
    type: ActionConsts.Recipes.SetSearchTerm,
    payload: term,
  }),

  SetFilters: (data: any) => ({
    type: ActionConsts.Recipes.SetFilters,
    payload: data,
  }),

  FetchData: (searchTerm: string, filters: string, pageNumber: number) => {
    return async (dispatch: Dispatch) => {
      if (searchTerm.length < 2) {
        searchTerm = ''
      }
      dispatch(RecipeActions.SetLoading(true))
      const data = await BroodService.GetRecipes(searchTerm, filters, pageNumber)
      dispatch(RecipeActions.SetLoading(false))

      if (data) {
        dispatch(RecipeActions.SetData(data.data))
      }
    }
  },

  SetData: (data: any) => ({
    type: ActionConsts.Recipes.SetData,
    payload: data,
  }),
  SetLoading: (data: any) => ({
    type: ActionConsts.Recipes.SetLoading,
    payload: data,
  }),
}
