import { ActionConsts, TranslationKeysArray } from '@Definitions'
import { IGeneralStore } from '@Interfaces'

export const DictionaryActions = {
  SetDictionary: (dictionaryArray: IGeneralStore.IDictionaryItem[] = []) => {
    const missingKeys = TranslationKeysArray.filter(
      translationKey =>
        !dictionaryArray.some(dictionaryItem => dictionaryItem.key === translationKey),
    )

    if (missingKeys && missingKeys.length > 0) {
      console.warn('Missing translation keys:', missingKeys)
    }

    const payload = dictionaryArray.reduce((acc, curr) => {
      return { ...acc, [curr.key]: curr.translation }
    }, {})

    return {
      type: ActionConsts.Dictionary.SetDictionary,
      payload,
    }
  },

  ResetReducer: () => ({
    type: ActionConsts.Dictionary.ResetReducer,
  }),
}
