import { ActionConsts } from '@Definitions'
import { IAction, INewsStore } from '@Interfaces'

type IActionPayload = INewsStore.Actions.IDataPayload | string

/**
 * INITIAL_STATE
 */
export const INITIAL_STATE: INewsStore.IInitialState = {
  pageNumber: 1,
  news: {
    newsArticles: [],
    currentPage: 1,
    totalPages: 0,
  },
}

/**
 * REDUCER
 */
export const NewsReducer = (
  state: INewsStore.IInitialState = INITIAL_STATE,
  action: IAction<IActionPayload>,
) => {
  switch (action.type) {
    case ActionConsts.News.SetData:
      return {
        ...state,
        news: action.payload,
      }

    default:
      return state
  }
}

export default NewsReducer
