export const ActionConsts = {
  General: {
    Set: 'General__Set',
    GetInitialLocale: 'General_GetInitialLocale',
    FetchData: 'General_FetchData',
    SetLocale: 'General_SetLocale',
    SetData: 'General_SetData',
    SetLoading: 'General_SetLoading',
    SetCat: 'General_SetCat',
    SetSearchTerm: 'General_SetSearchTerm'
  },
  SearchBox: {
    ResetReducer: 'SearchBox_ResetReducer',
    SetReducer: 'SearchBox_SetReducer',
    FetchData: 'SearchBox_FetchData',
    SetData: 'SearchBox_SetData',
  },
  Dictionary: {
    SetDictionary: 'Dictionary_SetDictionary',
    ResetReducer: 'Dictionary_ResetReducer',
  },
  Recipes: {
    SetSearchTerm: 'Recipes_SetSearchTerm',
    SetFilters: 'Recipes_SetFilters',
    FetchData: 'Recipes_FetchData',
    ResetReducer: 'Recipes_ResetReducer',
    SetData: 'Recipes_SetData',
    SetLoading: 'Recipes_SetLoading',
  },
  News: {
    FetchData: 'News_FetchData',
    SetData: 'News_SetData',
    SetLoading: 'News_SetLoading',
  },
  Blogs: {
    FetchData: 'Blogs_FetchData',
    SetData: 'Blogs_SetData',
    SetLoading: 'Blogs_SetLoading',
  },
}
