import { ActionConsts } from '@Definitions'
import { IAction, IGeneralStore } from '@Interfaces'
import { languages, getInitialLocale } from '@Redux/general/helpers'

/**
 * INITIAL_STATE
 */
export const INITIAL_STATE: IGeneralStore.IInitialState = {
  currentLocale: getInitialLocale(),
  languages,
  header: {
    topMenu: [],
    mainMenu: [],
    socialItems: [],
    leftText: '',
    leftLogo: {
      desktopUrl: '',
      mobileUrl: '',
    },
    rightText: '',
    rightLogo: {
      desktopUrl: '',
      mobileUrl: '',
    },
    menuBarLogo: {
      desktopUrl: '',
      mobileUrl: '',
    },
  },
  footer: {
    logo: {
      desktopUrl: '',
      mobileUrl: '',
    },
    leftMenu: [],
    rightMenu: [],
    privacyCookiesLink: '',
    socialItems: [],
  },
  siteTitle: '',
  popupContent: '',
  popupLabelNo: '',
  popupLabelYes: '',
  popupLabelNoLink: '',
  dictionary: [],
  searchCategories: [],
  activeSearchCategories: [],
  searchTerm: '',
}

type IPayload = IGeneralStore.Actions.ISetPayload

/**
 * REDUCER
 */
export const GeneralReducer = (state = INITIAL_STATE, action: IAction<IPayload>) => {
  switch (action.type) {
    case ActionConsts.General.Set:
      return {
        ...state,
        header: action.payload ? action.payload.header : {},
        footer: action.payload ? action.payload.footer : {},
        siteTitle: action.payload ? action.payload.siteTitle : '',
        popupContent: action.payload ? action.payload.popupContent : '',
        popupLabelNo: action.payload ? action.payload.popupLabelNo : '',
        popupLabelYes: action.payload ? action.payload.popupLabelYes : '',
        popupLabelNoLink: action.payload ? action.payload.popupLabelNoLink : '',
        dictionary: action.payload ? action.payload.dictionary : [],
        searchCategories: action.payload ? action.payload.searchCategories : [],
      }

    case ActionConsts.General.SetCat:
      return {
        ...state,
        activeSearchCategories: action.payload ? action.payload : [],
      }

    case ActionConsts.General.SetSearchTerm:
      return {
        ...state,
        searchTerm: action.payload ? action.payload : [],
      }

    default:
      return state
  }
}
