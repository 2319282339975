import { ActionConsts } from '@Definitions'
import { BroodService } from '@Services'
import { Dispatch } from 'redux'

export const NewsActions = {
  FetchData: (pageNumber: number) => {
    return async (dispatch: Dispatch) => {
      dispatch(NewsActions.SetLoading(true))
      const data = await BroodService.GetNews(pageNumber)
      dispatch(NewsActions.SetLoading(false))

      if (data) {
        dispatch(NewsActions.SetData(data.data))
      }
    }
  },

  SetData: (data: any) => ({
    type: ActionConsts.News.SetData,
    payload: data,
  }),
  SetLoading: (data: any) => ({
    type: ActionConsts.News.SetLoading,
    payload: data,
  }),
}
