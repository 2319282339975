import React from 'react'
import { ISocial } from '@Interfaces'

// @ts-ignore
import TwitterSvg from '../../public/images/icons/twitter.svg'
// @ts-ignore
import FacebookSvg from '../../public/images/icons/facebook.svg'
// @ts-ignore
import InstagramSvg from '../../public/images/icons/instagram.svg'
// @ts-ignore
import YoutubeSvg from '../../public/images/icons/youtube.svg'
// @ts-ignore
import PinterestSvg from '../../public/images/icons/pinterest.svg'

export interface ISocialItems {
  [key: string]: ISocial[]
}

export const SocialItems: ISocialItems = {
  BE: [
    {
      image: '/images/icon-twitter.png',
      svg: <TwitterSvg />,
      url: 'https://twitter.com/broodengezond',
      alt: '',
    },
    {
      image: '/images/icon-facebook.png',
      svg: <FacebookSvg />,
      url: 'https://www.facebook.com/broodengezondheid/',
      alt: '',
    },
    {
      image: '/images/icon-instagram.png',
      svg: <InstagramSvg />,
      url: 'https://www.instagram.com/broodengezondheid_painetsante',
      alt: '',
    },
    {
      image: '/images/icon-youtube.png',
      svg: <YoutubeSvg />,
      url: 'https://www.youtube.com/channel/UC_wvdYdDgjOs7vfAq6XdbmQ',
      alt: '',
    },
  ],
  FR: [
    {
      image: '/images/icon-twitter.png',
      svg: <TwitterSvg />,
      url: 'https://twitter.com/painetsante',
      alt: '',
    },
    {
      image: '/images/icon-facebook.png',
      svg: <FacebookSvg />,
      url: 'https://www.facebook.com/painetsante?fref=ts',
      alt: '',
    },
    {
      image: '/images/icon-instagram.png',
      svg: <InstagramSvg />,
      url: 'https://www.instagram.com/broodengezondheid_painetsante',
      alt: '',
    },
    {
      image: '/images/icon-youtube.png',
      svg: <YoutubeSvg />,
      url: 'https://www.youtube.com/channel/UC_wvdYdDgjOs7vfAq6XdbmQ',
      alt: '',
    },
  ],
  NL: [
    {
      image: '/images/icon-twitter.png',
      svg: <TwitterSvg />,
      url: 'https://twitter.com/brood_net',
      alt: '',
    },
    {
      image: '/images/icon-facebook.png',
      svg: <FacebookSvg />,
      url: 'https://www.facebook.com/brood.net/?fref=ts',
      alt: '',
    },
    {
      image: '/images/icon-instagram.png',
      svg: <InstagramSvg />,
      url: 'https://www.instagram.com/broodnet',
      alt: '',
    },
    {
      image: '/images/icon-pinterest.png',
      svg: <PinterestSvg />,
      url: 'https://www.pinterest.com/broodnet',
      alt: '',
    },
    {
      image: '/images/icon-youtube.png',
      svg: <YoutubeSvg />,
      url: 'https://www.youtube.com/user/VoorlbureauBrood',
      alt: '',
    },
  ],
}
