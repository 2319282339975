import getConfig from 'next/config'

import { defaultTheme } from './default'
import { belgiumTheme } from './belgium'

const {
  publicRuntimeConfig: { API_SITE },
} = getConfig()

const theme = ['BE', 'FR'].indexOf(API_SITE) > -1 ? belgiumTheme : defaultTheme

export * from './extraColors'
export default theme
