import { IDictionaryStore } from '@Interfaces'

export const TranslationKeys: IDictionaryStore.IDictionary = {
  ContactFormErrorMessage: 'ContactFormErrorMessage',
  ContactFormSuccesMessage: 'ContactFormSuccesMessage',
  ContactFormSendTitle: 'ContactFormSendTitle',
  ContactFormSubmitButton: 'ContactFormSubmitButton',
  ContactFormTitle: 'ContactFormTitle',
  NewsletterFormSubmitButton: 'Aanmelden',
  Copyright: 'Copyright',
  EmailaddressInvalid: 'EmailaddressInvalid',
  FollowUs: 'FollowUs',
  FormEmail: 'FormEmail',
  FormMessage: 'FormMessage',
  FormName: 'FormName',
  FormRequired: 'FormRequired',
  IngredientsTitle: 'IngredientsTitle',
  MainSearchLabel: 'MainSearchLabel',
  NextPage: 'NextPage',
  NutrientsTitle: 'NutrientsTitle',
  PreviousPage: 'PreviousPage',
  PrivacyCookies: 'PrivacyCookies',
  RealisedBy: 'RealisedBy',
  ResultFound: 'ResultFound',
  ResultsFound: 'ResultsFound',
  Search: 'Search',
  NoRecipeItems: 'NoRecipeItems',
  ProfessionalPopupButton: 'ProfessionalPopupButton',
  ContactformProfMessage: 'ContactformProfMessage',
  ContactformProfButton: 'ContactformProfButton',
  FaqContactFormBeforeLinkText: 'FaqContactFormBeforeLinkText',
  FaqContactFormLinkText: 'FaqContactFormLinkText',
  FaqContactFormAfterLinkText: 'FaqContactFormAfterLinkText',
  SearchFilterResults: 'SearchFilterResults',
  SearchCategoryAll: 'SearchCategoryAll',
  ResetFilters: 'ResetFilters',
}

export const TranslationKeysArray = Object.keys(TranslationKeys)
